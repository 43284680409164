<script>
import Button from '../components/Button';

export default {
  name: 'Disclaimer',
  components: { Button },
  computed: {
    list() {
      return 8;
    },
  },
};
</script>

<template>
  <div class="disclaimer">
    <div class="container">
      <h1 class="disclaimer--title">{{ $t('disclaimer.title') }}</h1>
      <div class="description">
        <p v-for="key in [1, 2, 3, 4, 5, 6, 7, 8]" :key="key">
          {{ $t(`disclaimer.description-${key}`) }}
        </p>
      </div>
      <Button @click="$router.go(-1)">
        {{ $t('disclaimer.button') }}
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .disclaimer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .container {
      margin: 2rem auto;
      max-width: 80rem;
      width: calc(100vw - 4rem);
      padding: 0 2rem;
      margin-bottom: 50px;
      text-align: center;

      @media (min-width: 856px) {
        margin-bottom: 120px;
      }

      @media (min-width: 1400px) {
        margin-bottom: 140px;
      }
    }

    &--title {
      text-align: center;
      color: #000E89;
      font-weight: bold;
      font-size: 4rem;
      margin-top: 6rem;
      text-transform: uppercase;
    }

    .btn {
      margin: 0 auto;
    }

    .description {
      color: #000E89;
      margin-top: 70px;
      margin-bottom: 40px;
      line-height: 1.5;
      font-size: 16px;
      text-align: left;
    }

    .hide-description {
      max-width: 1170px;
      background-size: contain;
      width: 90%;
      height: 140px;
      position: relative;
      bottom: -70px;
    }
  }

  .binance {
    .description {
      color: #fff;
    }

    .disclaimer--title {
      color: #fff;
    }
  }

</style>
